import BasicLayout from '@/components/main';

const pre = 'shop'

export default {
    path: '/shop',
    name: 'shop',
    header: 'shop',
    redirect: {
        name: `${pre}shop`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/shop/shop.vue')
        },
        {
            path: 'maxon/:detail',
            name: `${pre}`,
            component: () => import('@/pages/shop/detail.vue')
        },
        {
            path: 'octane_rende/:detail',
            name: `${pre}`,
            component: () => import('@/pages/shop/octane_rende.vue')
        },
        {
            path: 'pay/cashier',
            name: `cashier`,
            component: () => import('@/pages/pay/cashier.vue')
        },
        {
            path: 'pay/result',
            name: `result`,
            component: () => import('@/pages/pay/result.vue')
        }
    ]
}
