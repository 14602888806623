import BasicLayout from '@/components/main';

const pre = 't'

export default {
    path: '/t',
    name: 't',
    header: 't',
    redirect: {
        name: `${pre}t`
    },
    component: BasicLayout,
    children: [
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/user/pages.vue')
        }
    ]
}
