// 请求接口地址 如果没有配置自动获取当前网址路径
var Url = '';
switch (process.env.NODE_ENV) {
	case 'development':
		Url = "http://www.industrial-software-test.com/api/v1";
		break;
	case 'production':
		Url = "https://www.dc-isdc.com/api/v1";
        // Url = "https://demo.product-api.cn/api/v1";
		break;
}
const VUE_APP_API_URL = Url || process.env.VUE_APP_API_URL || `${location.origin}/api/v1`
// 管理端ws
const VUE_APP_WS_ADMIN_URL = process.env.VUE_APP_WS_ADMIN_URL || `ws:${location.hostname}:20002`

const Setting = {
    // 接口请求地址
    apiBaseURL: VUE_APP_API_URL,
    // adminsocket连接
    wsAdminSocketUrl: VUE_APP_WS_ADMIN_URL,
    // 路由模式，可选值为 history 或 hash
    routerMode: 'history',
    // 页面切换时，是否显示模拟的进度条
    showProgressBar: true
}

export default Setting
