<template>
    <div class="footer">
        <div class="main w">
            <div class="list-group">
                <div class="group-item"
                v-for="item in groupData">
                    <div class="group-item-container">
                        <h3 class="group-item-head">{{ item.title }}</h3>
                        <ul class="list-link-group">
                            <li class="group-item-link"
                            v-for="items in item.items">
                                <a :href="items.url" class="group-item-link-text" target="_blank">{{ items.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="locale">
            <div class="localeItem" v-for="(item, index) in clientList" :key="index">
                <el-popover trigger="hover" placement="top" v-if="item.qr_items.length > 0">
                    <div class="qr">
                        <div class="qr-content" v-for="items in item.qr_items">
                            <el-image class="qr-img" :src="items.image" fit="cover"></el-image>
                            <div class="qr-text">
                                <div>扫描二维码</div>
                                <div>{{ items.qr_name }}</div>
                            </div>
                        </div>
                    </div>
                    <div slot="reference" class="wechat download-block">
                        <img :src="item.image"/>
                        <img class="active" :src="item.image_active"/>
                    </div>
                </el-popover>
                <div v-if="item.qr_items.length == 0" class="wechat download-block">
                    <img :src="item.image"/>
                    <img class="active" :src="item.image_active"/>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="copyrightWrapper">
                <div class="copyrightList">
                    <span class="copyrightItem">{{ copyright }}</span>
                    <a v-if="record" class="copyrightItem" href="https://beian.miit.gov.cn/" target="_blank">{{ record }}</a>
                    <!-- <a class="copyrightItem" href="https://beian.miit.gov.cn/" target="_blank">
                        <img src="https://p1-hera.feishucdn.com/tos-cn-i-jbbdkfciu3/24fe94f4e7674d41af176c75560f3ba7~tplv-jbbdkfciu3-image:0:0.image" style="width: 14px; display: inline-block; top: 3px; position: relative;"/>
                        京公网安备 110802029085号
                    </a> -->
                    <!-- <a class="copyrightItem" href="https://beian.miit.gov.cn/" target="_blank">增值电信业务经营许可证：京B2-201120249</a> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { navigationApi } from '@/api/common';
export default {
    name: 'nav-footer',
    data() {
        return {
            groupData: [],
            clientList: [],
            copyright: '',
            record: ''
        }
    },
    created() {
        this.__loadData();
    },
    mounted() {

    },
    methods: {
        __loadData () {
            this._navigationFooterApi();
        },
        _navigationFooterApi () {
            navigationApi('footer').then(res => {
                if (res.status == 200) {
                    var data = res.data;
                    this.groupData = data.footer;
                    this.clientList = data.client;
                    this.copyright = data.copyright;
                    this.record = data.record;
                }
            });
        }
    }
}
</script>
<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: none;
}
.qr {
    display: flex;
    .qr-content {
        width: 124px;
        margin-right: 12px;
        margin-bottom: 6px;
        &:last-child{
            margin-right: 0;
        }
        .qr-img {
            display: block;
            width: 100%;
            height: 124px;
            margin: 0 0 10px;
        }
        .qr-text {
            text-align: center;
            color: #55585c;
            padding-top: 8px;
            border-top: 0.5px solid #b4babd;
        }
    }
}
.footer {
    .list-group{
        display: flex;
        justify-content: center;
        padding-top: 80px;
        padding-bottom: 42px;
        .group-item {
            width: 16.666666666666666%;
        }
        .group-item-container {
            width: 90%;
            .group-item-head{
                font-weight: 600;
                font-size: 14px;
                color: #171a1d;
                margin-bottom: 18px;
                line-height: 22px;
            }
            .group-item-link{
                margin-bottom: 8px;
                .group-item-link-text {
                    display: inline-block;
                    font-weight: 400;
                    font-size: 14px;
                    color: #1f2329;
                    line-height: 22px;
                    text-decoration: none;
                    opacity: 0.6;
                    &:hover {
                        opacity: 1;
                        color: #29a2ff;
                    }
                }
            }
        }
    }
    .locale {
        background-color: #f8f9fa;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        .localeItem{
            cursor: pointer;
            margin-right: 16px;
            display: inline-block;
            position: relative;
            .download-block {
                // position: relative;
                cursor: pointer;
                .active {
                    display: none;
                }
                &:hover{
                    img {
                        display: none;
                    }
                    .active {
                        display: block;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                }            
            }
        }
        .wechat {
            width: 111px;
            height: 42px;
        }
        .douyin {
            width: 84px;
            height: 42px;
        }
        .bili {
            width: 84px;
            height: 42px;
        }
    }
    .copyright {
        background-color: #f5f6f7;
        .copyrightList {
            color: #646a73;
            font-size: 12px;
            line-height: 36px;
            margin: 0 auto;
            max-width: 1296px;
            padding: 8px 48px;
            text-align: center;
            .copyrightItem {
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>