// import { getUserInfo } from "../../api/user.js";
import { USER_INFO, LOGIN_STATUS, UID, EXPIRES_TIME} from '../../config/cache';
import Cache from '../../utils/cache';

const state = {
	token: Cache.get(LOGIN_STATUS) || false,
	userInfo: Cache.get(USER_INFO, true) || {},
	uid: Cache.get(UID) || 0,
	homeActive: false,
	phoneStatus: true
};

const mutations = {
	LOGIN(state, opt) {
		state.token = opt.token;
		Cache.set(LOGIN_STATUS, opt.token);
		Cache.set(EXPIRES_TIME, opt.time);
	},
	UPDATE_USERINFO(state, userInfo) {
		state.userInfo = userInfo;
		Cache.set(USER_INFO, userInfo);
	},
	SETUID(state,val){
		state.uid = val;
		Cache.set(UID, val);
	},
	SETPHONESTATUS(state,val){
		state.phoneStatus = val;
	},
	UPDATE_LOGIN(state, token) {
		state.token = token;
	},
	LOGOUT(state) {
		state.token = false;
		state.uid = 0;
		state.userInfo = {}
		Cache.clear(EXPIRES_TIME);
		Cache.clear(LOGIN_STATUS);
		Cache.clear(USER_INFO);
		Cache.clear(UID);
	},
	OPEN_HOME(state) {
		state.homeActive = true;
	},
	CLOSE_HOME(state) {
		state.homeActive = false;
	},
};

const actions = {
// 	USERINFO({ state, commit }, force) {
// 		if (state.userInfo !== null && !force)
// 			return Promise.resolve(state.userInfo);
// 		else
// 			return new Promise(reslove => {
// 				getUserInfo().then(res => {
// 					commit("UPDATE_USERINFO", res.data);
// 					Cache.set(USER_INFO, res.data);
// 					reslove(res.data);
// 				});
// 			}).catch(() => {});
// 	}
};

export default {
	state,
	mutations,
	actions
};
