import BasicLayout from '@/components/main';

const pre = 'product'

export default {
    path: '/product',
    name: 'product',
    header: 'product',
    redirect: {
        name: `${pre}`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/product/product.vue')
        },
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/product/detail.vue')
        }
    ]
}
