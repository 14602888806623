import BasicLayout from '@/components/main';

const pre = 'about'

export default {
    path: '/about',
    name: 'about',
    header: 'about',
    redirect: {
        name: `${pre}about`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/about/about.vue')
        }
    ]
}
