<template>
    <div>
        <Modal class="signin" width="360"
            v-model="isWeChatLogin" :closable="false" :mask-closable="false" footer-hide
            :transitionNames="transitionNames"
        >
            <div class="header-container"
                v-loading="loadingPhone"
                element-loading-text="登录中"
            >
                <div class="header-container-title">
                    <div>微信安全登录</div>
                    <a class="header-container-close" @click="close">
                        <Icon type="ios-close" />
                    </a>
                </div>
                <div class="qrcode">
                    <div class="qrcode-div" v-loading="loading">
                        <template v-if="loading">
                            <div class="wechat-login--qrcode--group">
                                <div class="wechat-login-qrcode__invalid">
                                    <img class="wechat-login--qrcode__default-qr" :src="require('@/assets/qrcode/qr_code.jpg')">
                                </div>
                            </div>
                        </template>
                        <template v-if="!loading">
                            <img v-if="!isInvalid" class="wechat-login-qrcode" :src="codeList.url + codeList.ticket">
                            <div v-if="isInvalid" class="wechat-login--qrcode--group">
                                <div class="wechat-login-qrcode__invalid">
                                    <img class="wechat-login--qrcode__default-qr" :src="require('@/assets/qrcode/qr_code.jpg')">
                                    <div class="wechat-login--qrcode--mask"></div>
                                    <div class="wechat-login--qrcode__invalid--tip">二维码已失效</div>
                                    <div class="wechat-login--qrcode__invalid--btn" @click="onChangeRefresh">请点击刷新</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="wechat-login-protocol">
                    请关注公众号，登录/注册代表同意 <a target="_blank" href="/private/policy">隐私政策</a>
                </div>
            </div>
        </Modal>
        <!-- 绑定手机号 -->
        <BindingPhone ref="phone"/>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { getLoginCode, setScanLoginKey } from '@/api/public';
import BindingPhone from './BindingPhone.vue';
export default {
    name: 'wechat-login',
    components: { BindingPhone },
    data() {
        return {
            isWeChatLogin: false,
            transitionNames: ['fade'],
            loading: false,
            isInvalid: false,  //是否超时，false：未超时，true：超时
            codeList: {
                url: '',
                ticket: '',
                expire_seconds: '',
                fail_time: '',
                event_key: '',
            },
            timer: 0,     //倒计时秒数
            authTimer: null,
            loadingPhone: false
        }
    },
    watch: {
        '$store.state.wxchatLogin': {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.isWxchatLogin) {
                    //调取接口
                    this._QrCodeApi(newVal);
                } else {
                    this.isWeChatLogin = newVal.isWxchatLogin;
                }
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        __loadData () {},
        _QrCodeApi (newVal) {
            //打开弹窗
            this.isWeChatLogin = newVal.isWxchatLogin;
            //this._setScanLoginKey('NBMKr5nDSXh7chhEJbK9kYZS22');
            //return;
            //发起的网页授权
            this._getLoginCode();
        },
        _getLoginCode () {
            this.loading = true;
            getLoginCode().then(async res => {
                var data = res.data.data;
                this.codeList = data;
                this.loading = false;
                //轮询-检测扫码情况
                this._pollingQuery();
            }).catch(res => {
                this.loading = false;
                this.$Message.error(res.msg);
            });
        },
        onChangeRefresh () {
            this.isInvalid = false;
            this._getLoginCode();
        },
        //轮询-检测扫码情况
        _pollingQuery () {
            var data = this.codeList;
            this.timer = Math.round(data.expire_seconds/3);
            this.authTimer = setInterval(()=>{
                this.timer--;
                if(this.timer >= 0){
                    this._setScanLoginKey(data.event_key);
                }
            }, 3000);
        },
        _setScanLoginKey (event_key) {
            setScanLoginKey({ event_key: event_key }).then(async res => {
                //请求成功后，关闭弹窗，将用户信息保存
                if (res.status === 200) {
                    //登录后，关闭弹窗，显示绑定手机号弹窗
                    let data = res.data;
                    this.$store.commit("LOGIN", {
                        'token': data.token,
                        'time': data.exp_time
                    });
                    this.$store.commit("UPDATE_USERINFO", data.userInfo);
                    this.$store.commit("SETUID", data.userInfo.uid || 0);
                    this.loadingPhone = true;
                    //显示绑定手机号弹窗
                    setTimeout(() => {
                        this.loadingPhone = false;
                        if (!data.userInfo.phone) {
                            this.$refs.phone.isBindingPhone = true;
                        } else {
                            this.$Message.success(res.msg);
                        }
                        this.close();
                    }, 600);
                }
            }).catch(res => {
                if (res.status === 400011) {
                    this.isInvalid = true;
                    clearInterval(this.authTimer);
                }
            });
        },
        //关闭
        close () {
            this.loadingPhone = false;
            this.isInvalid = false;
            clearInterval(this.authTimer);
            this.setWxchatLogin(false);
        },
        ...mapMutations('wxchatLogin', ['setWxchatLogin'])
    }
}
</script>
<style lang="less" scoped>
.signin{
    /deep/.ivu-modal-body{
        padding: 0;
    }
    /deep/.el-loading-mask{
        border-radius: 6px;
    }
    .header-container{
        padding: 24px 16px 30px 16px;
        .header-container-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            line-height: 30px;
            font-weight: 700;
            height: 30px;
            color: #1c1f21;
            padding-left: 10px;
            .header-container-close{
                height: 40px;
                /deep/ .ivu-icon-ios-close {
                    font-size: 35px;
                    color: #999;
                    transition: color .2s ease;
                    &:hover {
                        color: #444;
                    }
                }
            }
        }
        .qrcode{
            text-align: center;
            .qrcode-div{
                width: 225px;
                height: 225px;
                margin: auto;
            }
            .wechat-login-qrcode,.wechat-login--qrcode__default-qr,.wechat-login-qrcode__invalid,.wechat-login--qrcode--mask{
                width: 225px;
                height: 225px;
            }
            .wechat-login--qrcode--group {
                .wechat-login-qrcode__invalid{
                    position: relative;
                    margin: 0 auto;
                }
            }
            .wechat-login--qrcode--mask {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(255,255,255,.9);
            }
            .wechat-login--qrcode__invalid--tip {
                width: 143px;
                text-align: center;
                color: rgb(25, 25, 26);
                position: absolute;
                top: 70px;
                left: 50%;
                transform: translate(-50%);
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
            }
            .wechat-login--qrcode__invalid--btn {
                color: #fff;
                background: #5359fd;
                width: 150px;
                height: 40px;
                padding: 10px 0;
                text-align: center;
                box-sizing: border-box;
                border-radius: 6px;
                cursor: pointer;
                bottom: 70px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                transition: all .15s ease-in-out;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                &:hover {
                    background-color: #616ffe;
                    border-color: #616ffe;
                }
            }
        }
        .wechat-login-protocol {
            text-align: center;
            margin: 4px 0 0;
            color: rgb(85, 85, 87);
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            a{
                height: 31px;
                color: rgb(83, 89, 253);
            }
        }
    }
}
</style>