import BasicLayout from '@/components/main';

const pre = 'private'

export default {
    path: '/private',
    name: 'private',
    header: 'private',
    redirect: {
        name: `${pre}`
    },
    component: BasicLayout,
    children: [
        {
            path: 'policy',
            name: `${pre}`,
            component: () => import('@/pages/private/private')
        }
    ]
}
