<template>
    <div>
        <div class="home" v-if="searchWidth >= 1024">
            <NavHeader/>
            <router-view></router-view>
            <NavFooter />
        </div>
        <div class="mobile" v-else>
            <div class="u-center">
                <div class="wechat">
                    <div><p>长按或扫描下方二维码了解更多~</p></div>
                    <div><el-image class="image" fit="cover" :src="require('@/assets/qrcode/qr_code.jpg')" lazy></el-image></div>
                </div>
                <div class="routine">
                    <div><p>小程序维护中，敬请期待~</p></div>
                    <div><el-image class="image" fit="cover" :src="require('@/assets/qrcode/applet.jpg')" lazy></el-image></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavFooter from '@/components/NavFooter.vue';
export default {
    name: 'HomeView',
    components: {
        NavHeader,
        NavFooter
    },
    data() { 
        return { 
            searchWidth: 0
        }
    },
    created () {
        this.handleResize();
    },
    mounted () {
        //窗口尺寸改变
        window.onresize = () => {
            return (() => {
                this.handleResize();
            })();
        };
    },
    destroyed() {
        // 销毁
        window.onresize = null;
    },
    methods: {
        // 监听页面宽度变化，刷新表格
        handleResize () {
            this.searchWidth = window.innerWidth;
        },
    }
}
</script>
<style lang="less" scoped>
.wechat {
    padding-top: 48px;
}
.routine {
    padding-top: 30px;
}
.image {
    width: 148px;
}
</style>  