import BasicLayout from '@/components/main';

const pre = 'u'

export default {
    path: '/u',
    name: 'u',
    header: 'u',
    redirect: {
        name: `${pre}u`
    },
    component: BasicLayout,
    children: [
        {
            path: 'order',
            name: `${pre}`,
            component: () => import('@/pages/user/order.vue')
        },
        {
            path: 'course',
            name: `${pre}`,
            component: () => import('@/pages/user/course.vue')
        },
        {
            path: 'coupon',
            name: `${pre}`,
            component: () => import('@/pages/user/coupon.vue')
        },
        {
            path: 'collect',
            name: `${pre}`,
            component: () => import('@/pages/user/collect.vue')
        },
        {
            path: 'articles',
            name: `${pre}`,
            component: () => import('@/pages/user/articles.vue')
        },
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/user/pagesAgent.vue')
        },
    ]
}
