import BasicLayout from '@/components/main';

const pre = 'gather_'

export default {
    path: '/gather',
    name: 'gather',
    header: 'gather',
    redirect: {
        name: `${pre}gather`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}gather`,
            component: () => import('@/pages/gather/gather.vue')
        }
    ]
}
