import app from "./app";
import hotWords from "./hotWords";
import indexData from './indexData.js';
import wxchatLogin from './wxchatLogin.js'
export default {
  app,
  hotWords,
	indexData,
  wxchatLogin
};
