import BasicLayout from '@/components/main';

const pre = 'error'

export default {
    path: '/',
    name: 'error',
    header: 'error',
    redirect: {
        name: `${pre}`
    },
    component: BasicLayout,
    children: [
        {
            path: '404',
            name: `${pre}`,
            component: () => import('@/pages/error/404')
        },
        {
            path: '403',
            name: `${pre}`,
            component: () => import('@/pages/error/403')
        }
    ]
}
