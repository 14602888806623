import Vue from 'vue'
import { Message, Icon, Modal, Spin, Button, Input, Row, Col, Scroll, Dropdown, DropdownMenu,
    DropdownItem, Card, Form, FormItem, Poptip, Table, Divider, Page, Select, Option, Alert, Avatar } from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.component('Avatar', Avatar);
Vue.component('Alert', Alert);
Vue.component('Option', Option);
Vue.component('Select', Select);
Vue.component('Page', Page);
Vue.component('Divider', Divider);
Vue.component('Table', Table);
Vue.component('Poptip', Poptip);
Vue.component('Form', Form);
Vue.component('FormItem', FormItem);
Vue.component('Card', Card);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Scroll', Scroll);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Input', Input);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
Vue.component('Spin', Spin);

Vue.prototype.$Message = Message;
Vue.prototype.$Modal = Modal;
Vue.prototype.$Spin = Spin;