import axios from 'axios';
import { Message } from 'iview';
import Setting from '@/setting';
import store from '../store';
import router from '@/router';
import { tokenVerify } from "../api/user.js";

//请求次数
let requestNumber = 0;

const service = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 10000 // 请求超时时间
})

axios.defaults.withCredentials = true;// 携带cookie

// 请求拦截器
service.interceptors.request.use(
    config => {
        var noAuth = config.noAuth ? config.noAuth : false;
        if (noAuth) {
            //登录过期，自动登录，更新Token 
            //if (store.state.app.token && !checkLogin()) {
            //}
        }
        config.baseURL = Setting.apiBaseURL;
        const token = store.state.app.token;
        if (token) {
            config.headers['Authori-zation'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    async response => {
        let status = response.data ? response.data.status : 0;
        const code = status;
        switch (code) {
        case 200:
            return response.data;
        case 400:case 400011:case 400012:
            return Promise.reject(response.data || { msg: '未知错误' });
        case 410000://410000：未登录
            localStorage.clear();
            return Promise.reject(response.data || { msg: '未知错误' });
        case 410001:case 410002://410001：登录已过期,请重新登录 410002：登录状态有误,请重新登录
            requestNumber += 1;
            if (requestNumber > 3) {
                localStorage.clear();
                location.reload();
                break;
            }
            let res = await tokenVerify({token: store.state.app.uid});
            if (res.status == 200) {
                store.commit("LOGIN", {'token': res.data.token, 'time': res.data.exp_time});
            }
            return service(response.config);
        case 10001:
            router.push({ path: '/404' });
            return Promise.reject(response.data || { msg: '未知错误' })
        case 10002:
            localStorage.clear();
            location.reload();
            break;    
        default:
            break
        }
    },
    error => {
        Message.error(error.msg);
        return Promise.reject(error)
    }
)

export default service
