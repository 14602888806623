import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//引入自定义样式
import './styles/index.less';

//ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//ViewUI
import "./ViewUI/ViewUI.js";

//微信相关
import Auth from './libs/wechat';
Vue.prototype.$wechat = Auth;

//缓存相关
import Cache from './utils/cache'
Vue.prototype.$Cache = Cache;

//视频组件
// import VueCoreVideoPlayer from "vue-core-video-player";
// Vue.use(VueCoreVideoPlayer)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
