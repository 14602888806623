/**
 * 布局配置
 * */
export default {
    namespaced: true,
    state: {
		isWxchatLogin: false,  // 登录弹窗是否显示
		isPhoneBinding: false, // 是否绑定弹窗
    },
	getters: {},
	actions: {},
    mutations: {
		setWxchatLogin (state, val) {
			state.isWxchatLogin = val;
		},
		setBindingPhone (state, val) {
			state.isPhoneBinding = val;
		}
	}
}
