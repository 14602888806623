import request from '@/libs/request'

/**
 * @description 底部导航
 * @param {Object} param params {Object} 传值
 */
export function navigationApi (template_name) {
    return request({
        url: `navigation/` + template_name,
        method: 'GET'
    })
}

/**
 * 隐私政策
 */
export function privateDetailApi () {
    return request({
        url: `private`,
        method: 'GET'
    })
}
