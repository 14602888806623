import BasicLayout from '@/components/main';

const pre = 'index_'

export default {
    path: '/',
    name: 'index',
    header: 'index',
    redirect: {
        name: `${pre}index`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}index`,
            component: () => import('@/pages/index')
        }
    ]
}
