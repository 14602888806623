import BasicLayout from '@/components/main/common';

const pre = 'receive'

export default {
    path: '/receive/coupon',
    name: 'receive',
    header: 'receive',
    redirect: {
        name: `${pre}receive`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/receive/coupon.vue')
        }
    ]
}

