var WechatJSSDK = require('jweixin-module');
import { getWechatConfig, wechatAuth } from "@/api/public";
import { WX_AUTH, STATE_KEY, LOGINTYPE, BACK_URL } from '@/config/cache';
import { parseQuery } from '@/utils';
import store from '@/store';
import Cache from '@/utils/cache';

class AuthWechat {
	constructor() {
		this.instance = WechatJSSDK;
		
		//是否实例化
		this.status = false;

		this.initConfig = {};
	}
	
	isAndroid(){
		let u = navigator.userAgent;
		return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
	}
	
	signLink() {
		if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
			window.entryUrl = location.href
		}
		return /(Android)/i.test(navigator.userAgent) ? location.href : window.entryUrl;
	}

	/**
	 * 初始化wechat(分享配置)
	 */
	async wechat() {
		var res = await getWechatConfig({ url: this.signLink() });
		this.instance.config(res.data);
		this.initConfig = res.data;
		this.status = true;
		this.instance.ready(this.instance);
		// return new Promise((resolve, reject) => {
		// 	getWechatConfig({ url: this.signLink() }).then(res => {
		// 		this.instance.config(res.data);
		// 		this.initConfig = res.data;
		// 		this.status = true;
		// 		this.instance.ready(() => {
		// 			resolve(this.instance);
		// 		});
		// 		console.log(res.data);
		// 	}).catch(err => {
		// 		console.log(err);
		// 		this.status = false;
		// 		reject(err);
		// 	});
		// });
	}

	/**
	 * 验证是否初始化
	 */
	verifyInstance() {
		let that = this;
		return new Promise((resolve, reject) => {
			if (that.instance === null && !that.status) {
				that.wechat().then(res => {
					resolve(that.instance);
				}).catch(() => {
					return reject();
				})
			} else {
				return resolve(that.instance);
			}
		})
	}

	// 微信公众号的共享地址
	openAddress() {
		return new Promise((resolve, reject) => {
			this.wechat().then(wx => {
				this.toPromise(wx.openAddress).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			})
		});
	}
	
	// 获取经纬度；
	location(){
		return new Promise((resolve, reject) => {
			this.wechat().then(wx => {
				this.toPromise(wx.getLocation,{type: 'wgs84'}).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			})
		});
	}
	
	// 使用微信内置地图查看位置接口；
	seeLocation(config){
		return new Promise((resolve, reject) => {
			this.wechat().then(wx => {
				this.toPromise(wx.openLocation, config).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			})
		});
	}

	/**
	 * 微信支付
	 * @param {Object} config
	 */
	pay(config) {
		return new Promise((resolve, reject) => {
			this.wechat().then((wx) => {
				this.toPromise(wx.chooseWXPay, config).then(res => {
					resolve(res);
				}).catch(res => {
					reject(res);
				});
			}).catch(res => {
				reject(res);
			});
		});
	}

	toPromise(fn, config = {}) {
		return new Promise((resolve, reject) => {
			fn({
				...config,
				success(res) {
					resolve(res);
				},
				fail(err) {
					reject(err);
				},
				complete(err) {
					reject(err);
				},
				cancel(err) {
					reject(err);
				}
			});
		});
	}
	
	/**
	 * 自动去授权
	 */
	oAuth(snsapiBase, url) {
		Cache.clear('authIng');
		if (Cache.get('authIng')) return;
		if (Cache.get(WX_AUTH) && store.state.app.token && snsapiBase == 'snsapi_base') return;
		let { code } = parseQuery();
		let snsapiCode = Cache.get('snsapiCode');
		if(code instanceof Array){
			code = code[code.length-1]
		}
		if(snsapiCode instanceof Array){
			snsapiCode = snsapiCode[snsapiCode.length-1]
		}
		if (!code || code == snsapiCode){
			Cache.set('authIng', true);
			return this.toAuth(snsapiBase, url);
		}else{ 
			// console.log('授权登陆获取token');
			//授权登陆获取token
			if(Cache.has('snsapiKey')) {
				// return this.auth(code).catch(error=>{
				// 	uni.showToast({
				// 		title: error,
				// 		icon:'none'
				// 	})
				// });
			}
		}
	}
	
	/**
	 * 授权登陆获取token
	 * @param {Object} code
	 */
	auth(code) {
		return new Promise((resolve, reject) => {
			wechatAuthV2(code, parseInt(Cache.get("spid"))).then(({ data }) => {
				Cache.set(WX_AUTH, code);
				Cache.clear(STATE_KEY);
				resolve(data);
			}).catch(reject);
		});
	}

	/**
	 * 跳转自动登陆
	 */
	toAuth(snsapiBase, backUrl) {
		let that = this;
		return new Promise((resolve, reject) => {
			this.wechat().then((wx) => {
				location.href = this.getAuthUrl(that.initConfig.appId, snsapiBase, backUrl);
			}).catch(res => {
				reject(res);
			});
		});
	}

	/**
	 * 获取跳转授权后的地址
	 * @param {Object} appId
	 */
	getAuthUrl(appId, snsapiBase, backUrl) {
		if (backUrl) {
			let backUrlArr = backUrl.split('&');
			let newUrlArr = backUrlArr.filter(item => {
				if (item.indexOf('code=') === -1 || item.indexOf('back_url=') === -1 || item.indexOf('scope=') === -1) {
					return item;
				}
			});
			backUrl = newUrlArr.join('&');
		}
		//跳转到指定页面URL location.origin // www.maxon-services.cn
		//var origin = 'http://www.maxon-services.cn';
		let url = `${location.origin}${backUrl}`

		if(url.indexOf('?') == -1){
			url = url+'?'
		}else{
			url = url+'&'
		}
		
		const redirect_uri = encodeURIComponent(
			`${url}scope=${snsapiBase}&back_url=` +
			encodeURIComponent(
				encodeURIComponent(
					Cache.get(BACK_URL) ? Cache.get(BACK_URL) : location.pathname + location.search
				)
			)
		);

		Cache.clear(BACK_URL);
		const state = encodeURIComponent(
			("" + Math.random()).split(".")[1] + "authorizestate"
		);
		Cache.set(STATE_KEY, state);
		if(snsapiBase === 'snsapi_base'){
			return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${state}&connect_redirect=1#wechat_redirect`;
		}else{
			return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}&connect_redirect=1#wechat_redirect`;
		}
	}

	/**
	 * 绑定事件
	 * @param {Object} name 事件名
	 * @param {Object} config 参数
	 */
	wechatEvevt(name, config) {
		let that = this;
		return new Promise((resolve, reject) => {
			let configDefault = {
				fail(res) {
					if (that.instance) return reject({
						is_ready: true,
						wx: that.instance
					});
					that.verifyInstance().then(wx => {
						return reject({
							is_ready: true,
							wx: wx
						});
					})
				},
				success(res) {
					return resolve(res,2222);
				}
			};
			Object.assign(configDefault, config);
			that.wechat().then(wx => {
				if (typeof name === 'object') {
					name.forEach(item => {
						wx[item] && wx[item](configDefault)
					})
				} else {
					wx[name] && wx[name](configDefault)
				}
			})
		});
	}
	
	//判断是否为微信
	isWeixin() {
		return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
	}
}

export default new AuthWechat();
