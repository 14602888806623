import BasicLayout from '@/components/main';

const pre = 'solution'

export default {
    path: '/solution',
    name: 'solution',
    header: 'solution',
    redirect: {
        name: `${pre}`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/solution/solution.vue')
        },
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/solution/detail.vue')
        }
    ]
}
