import BasicLayout from '@/components/main';

const pre = 'know'

export default {
    path: '/know',
    name: 'know',
    header: 'know',
    redirect: {
        name: `${pre}know`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/know/know.vue')
        },
        {
            path: 'search',
            name: `${pre}`,
            component: () => import('@/pages/know/search.vue')
        },
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/know/detail.vue')
        }
    ]
}
