<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'common',
    data() { 
        return { 
            searchWidth: 0
        }
    },
    created () {
        // this.handleResize();
    },
    mounted () {
        // //窗口尺寸改变
        // window.onresize = () => {
        //     return (() => {
        //         this.handleResize();
        //     })();
        // };
    },
    // destroyed() {
    //     // 销毁
    //     window.onresize = null;
    // },
    // methods: {
    //     // 监听页面宽度变化，刷新表格
    //     handleResize () {
    //         this.searchWidth = window.innerWidth;
    //     },
    // }
}
</script>