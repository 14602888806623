import BasicLayout from '@/components/main';

const pre = 'user'

export default {
    path: '/user',
    name: 'user',
    header: 'user',
    redirect: {
        name: `${pre}user`
    },
    component: BasicLayout,
    children: [
        {
            path: 'setbindsns',
            name: `${pre}`,
            component: () => import('@/pages/user/setbindsns.vue')
        },
        {
            path: 'school',
            name: `${pre}`,
            component: () => import('@/pages/user/school.vue')
        }
    ]
}
