<template>
    <div>
        <Modal class="binding-phone" width="360"
            v-model="isBindingPhone" :closable="false" :mask-closable="false" footer-hide
            :transitionNames="transitionNames"
        >
            <div class="header-container">
                <div class="header-container-title">
                    <div>绑定手机号</div>
                </div>
                <div class="input--container bind-phone-box--input">
                    <el-input class="input--box" :class="isPhoneError ? 'input--box--error' : ''" v-model.trim="form.phone" maxlength="11" type="tel" oninput="value=value.replace(/\D/g,'');" placeholder="请输入要绑定的手机号"></el-input>
                    <div v-if="isPhoneError" class="input--error-tip animated fadeIn">{{ phoneErrorTip }}</div>
                </div>
                <div class="input--container">
                    <el-input class="input--box" :class="isCodeError ? 'input--box--error' : ''" v-model.trim="form.captcha" maxlength="6" oninput="value=value.replace(/\D/g,'');" placeholder="验证码"></el-input>
                    <span class="bind-phone-box--code verification" @click="onChangeCode" v-if="!isCode">发送</span>
                    <span class="bind-phone-box--code" v-if="isCode">{{timer}}s</span>
                    <div v-if="isCodeError" class="input--error-tip animated fadeIn">{{ codeErrorTip }}</div>
                </div>
                <el-button class="btn--next" :loading="loading" @click="onChangeConfirm">确定</el-button>
                <div class="btn--next btn--next--normal" @click="onChangeSkip">跳过</div>
            </div>
        </Modal>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { bindingPhone, registerVerify } from '@/api/user';
export default {
    name: 'binding-phone',
    data() {
        return {
            isBindingPhone: false,
            transitionNames: ['fade'],
            isPhoneError: false,
            phoneErrorTip: '请输入手机号',
            isCodeError: false,
            codeErrorTip: '请输入验证码',
            loading: false,
            isCode: false,
            form: {
                phone: '',
                captcha: ''
            },
            timer: 0,
            authTimer: null
        }
    },
    watch: {
        '$store.state.wxchatLogin': {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.isPhoneBinding) {
                    this.isBindingPhone = newVal.isPhoneBinding;
                }
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        __loadData () {},
        //短信验证码
        onChangeCode () {
            var form = this.form;
            if (!form.phone) {
                this.phoneErrorTip = '请输入手机号';
                this.isPhoneError = true;
                return false;
            }
            const reg = /^1[3-9]\d{9}$/;
            if(form.phone && !reg.test(form.phone)){
                this.isPhoneError = true;
                this.phoneErrorTip = '手机号格式错误，请重新输入！';
                return false;
            }
            this.isPhoneError = false;
            registerVerify({ phone: form.phone }).then(async res => {
                this.isCode = true;
                this.timer = 60;
                this.authTimer = setInterval(()=>{
                    this.timer--;
                    if(this.timer == 0){
                        this.isCode = false;
                        clearInterval(this.authTimer);
                    }
                }, 1000);
                var data = res.data || '';
                if (data) {
                    this.$Message.success('短信验证码：' + data.code);
                }
            }).catch(res => {
                this.$Message.error(res.msg);
            });

            
        },
        //确定绑定
        onChangeConfirm () {
            var form = this.form;
            if (!form.phone) {
                this.phoneErrorTip = '请输入手机号';
                this.isPhoneError = true;
                return false;
            }
            const reg = /^1[3-9]\d{9}$/;
            if(form.phone && !reg.test(form.phone)){
                this.isPhoneError = true;
                this.phoneErrorTip = '手机号格式错误，请重新输入！';
                return false;
            }
            this.isPhoneError = false;
            if (!form.captcha) {
                this.isCodeError = true;
                this.codeErrorTip = '请输入验证码';
                return false;
            }
            this.isCodeError = false;
            this.loading = true;
            bindingPhone(form).then(async res => {
                this.$emit('setBindingPhone', true);
                this.loading = false;
                this.$Message.success(res.msg);

                var userInfo = this.$store.state.app.userInfo;
                userInfo.phone = form.phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
                this.$store.commit("UPDATE_USERINFO", userInfo);

                this.onChangeSkip();//关闭    
            }).catch(res => {
                this.loading = false;
                this.$Message.error(res.msg);
            });
        },
        //跳过绑定环节
        onChangeSkip () {
            this.isBindingPhone = false;
            this.isPhoneError = false;
            this.isCodeError = false;
            this.loading = false;
            this.isCode = false;
            this.form = {
                phone: '',
                captcha: ''
            };
            clearInterval(this.authTimer);
            this.setBindingPhone(false);
            this.$emit('setBindingPhoneSkip', true);
        },
        ...mapMutations('wxchatLogin', ['setWxchatLogin', 'setBindingPhone'])
    }
}
</script>
<style lang="less" scoped>
.binding-phone{
    .header-container{
        padding: 12px;
        .header-container-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            line-height: 30px;
            font-weight: 700;
            height: 30px;
            color: #1c1f21;
        }
        .input--container{
            position: relative;
            margin-bottom: 12px;
            &.bind-phone-box--input{
                margin-top: 18px;
            }
            .input--box {
                &:hover {
                    /deep/.el-input__inner{
                        background: rgba(18, 17, 42, 0.05);
                        border: 1px solid transparent;
                    }
                }
                /deep/.el-input__inner{
                    background: #f7f7f9;
                    border: 1px solid transparent;
                    box-sizing: border-box;
                    outline: none;
                    color: #19191a;
                    transition: all .15s ease-in-out;
                    padding: 14px 8px;
                    &:focus{
                        border: 1px solid #5359fd;
                    }
                }
            }
            .input--box--error{
                &:hover {
                    /deep/.el-input__inner{
                        background: #f7f7f9;
                    border: 1px solid #f43529;
                    }
                }
                /deep/.el-input__inner{
                    border: 1px solid #f43529;
                    &:focus{
                        border: 1px solid #f43529;
                    }
                }
            }
            .input--error-tip {
                margin-top: 2px;
                color: #f43529;
                font-size: 12px;
                line-height: 20px;
                height: 20px;
                font-weight: 400;
            }
            .bind-phone-box--code{
                position: absolute;
                right: 8px;
                top: 9px;
                font-size: 14px;
                height: 22px;
                line-height: 22px;
                color: #555557;
                cursor: pointer;
                &.verification{
                    color: #5359fd;
                }
            }
        }
        .btn--next {
            width: 100%;
            height: 40px;
            padding: 14px 0;
            font-size: 14px;
            line-height: 11px;
            text-align: center;
            box-sizing: border-box;
            color: #fff;
            background: #5359fd;
            border-radius: 6px;
            cursor: pointer;
            user-select: none;
            font-weight: 500;
            transition: all .15s ease-in-out;
            &:hover {
                background: #616ffe;
            }
        }
        .btn--next--normal {
            background: #fff;
            color: #19191a;
            border: 1px solid rgba(6, 6, 31, 0.09);
            margin-top: 12px;
            &:hover {
                background: rgba(18, 17, 42, 0.05);
            }
        }
    }
    input::selection{
        color: #fff;
        background: #5359fd;
    }
}
</style>