import BasicLayout from '@/components/main';
// import frameOut from './modules/frameOut'
import index from './modules/index';
import course from './modules/course';
import live from './modules/live';
import customers from './modules/customers';
import solution from './modules/solution';
import trade from './modules/trade';
import know from './modules/know';
import gather from './modules/gather';
import product from './modules/product';
import about from './modules/about';
import shop from './modules/shop';
import user from './modules/user';
import u from './modules/u';
import t from './modules/t';
import error from './modules/error';
import privatePolicy from './modules/private';
import receive from './modules/receive';
import personal from './modules/personal';

/**
 * 在主框架内显示
 */
const frameIn = [
    {
        path: '/admin/',
        meta: {
            title: '工业数云'
        },
        redirect: {
            name: 'home_index'
        },
        component: BasicLayout,
        children: [
            // {
            //     path: '/admin/system/user',
            //     name: `systemUser`,
            //     meta: {
            //         auth: true,
            //         title: '个人中心'
            //     },
            //     component: () => import('@/pages/setting/user/index')
            // },
            // 刷新页面 必须保留
            {
                path: 'refresh',
                name: 'refresh',
                hidden: true,
                component: {
                    beforeRouteEnter (to, from, next) {
                        next(instance => instance.$router.replace(from.fullPath))
                    },
                    render: h => h()
                }
            },
            // 页面重定向 必须保留
            {
                path: 'redirect/:route*',
                name: 'redirect',
                hidden: true,
                component: {
                    beforeRouteEnter (to, from, next) {
                        next(instance => instance.$router.replace(JSON.parse(from.params.route)))
                    },
                    render: h => h()
                }
            }
        ]
    },
    index,
    course,
    live,
    receive,
    customers,
    solution,
    trade,
    know,
    gather,
    product,
    about,
    shop,
    user,
    u,
    t,
    personal,
    error,
    privatePolicy
]

/**
 * 在主框架之外显示
 */
// const frameOuts = frameOut

// 导出需要显示菜单的
export const frameInRoutes = frameIn;

// 重新组织后导出
export default [
    // ...frameOuts,
    ...frameIn
]
