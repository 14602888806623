import BasicLayout from '@/components/main/common';

const pre = 'account'

export default {
    path: '/account',
    name: 'account',
    header: 'account',
    redirect: {
        name: `${pre}`
    },
    component: BasicLayout,
    children: [
        {
            path: 'user',
            name: `${pre}`,
            component: () => import('@/pages/personal/my/user.vue')
        },
        {
            path: 'order',
            name: `${pre}`,
            component: () => import('@/pages/personal/order/order.vue')
        },
        {
            path: 'order/detail',
            name: `${pre}`,
            component: () => import('@/pages/personal/order/details.vue')
        }
    ]
}

