import BasicLayout from '@/components/main';

const pre = 'course'

export default {
    path: '/course',
    name: 'course',
    header: 'course',
    redirect: {
        name: `${pre}course`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/course/course.vue')
        },
        {
            path: 'series/:detail',
            name: `${pre}`,
            component: () => import('@/pages/course/series.vue')
        },
        {
            path: 'video/:detail',
            name: `${pre}`,
            component: () => import('@/pages/course/video.vue')
        }
    ]
}
