<template>
    <div class="header">
        <div class="header-nav" :class="isKnowClass ? 'know' : ''">
            <div class="nav-topbar"></div>
            <div class="nav-header">
                <div class="w">
                    <div class="container">
                        <div class="header-logo">
                            <a href="/" class="logo-link" v-if="logoList.url">
                                <el-image class="image" :style="{width: logoList.width + 'px'}" :src="logoList.url" fit="cover"></el-image>
                            </a>
                        </div>
                        <div class="header-menu">
                            <div class="header-menu-item"
                            v-for="(item, index) in menuList" :key="index">
                                <Dropdown v-if="!item.isHamburgMenu">
                                    <a class="header-menu-itemLink" :class="selectUrl == item.select_url ? 'selected' : ''" :href="item.url">
                                        {{ item.title }} <i v-if="item.drop_down.length != 0" class="el-icon-arrow-down"></i>
                                    </a>
                                    <template v-if="item.drop_down.length != 0">
                                        <DropdownMenu slot="list">
                                            <DropdownItem v-for="(items, idx) in item.drop_down" :key="idx">
                                                <a style="color: #1c1f21;" :href="items.url" target="_blank">{{ items.title }}</a>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </template>
                                </Dropdown>
                                <a v-if="item.isHamburgMenu" href="javascript:void(0)"
                                class="header-menu-itemLink product" :class="selectUrl == item.select_url ? 'selected' : ''">
                                    <span class="header-menu-itemLinkText">
                                        {{ item.title }}<i v-if="item.drop_down.length != 0" class="el-icon-arrow-down"></i>
                                    </span>
                                    <div v-if="item.isHamburgMenu" class="head-nav-item-content animated fadeIn">
                                        <div class="w">
                                            <div class="item-product-list">
                                                <div class="item-product-list-right">
                                                    <div class="item-product-list-item" v-for="(items, idx) in item.drop_down" :key="idx">
                                                        <div class="item-product-list-item-title">{{ items.title }}</div>
                                                        <div class="item-product-list-item-desc">
                                                            <div v-for="(v, k) in items.product" :key="k">
                                                                <a :href="'/product/' + v.id" target="_blank"><span class="u-line-1">{{ v.title }}</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <el-divider></el-divider>
                                            <div class="whole">
                                                <span>
                                                    <a href="/product" target="_blank">全部产品</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="header-right">
                        <div class="login-area">
                            <ul class="header-unlogin">
                                <li class="header-signin" v-if="!isLogin" @click="login">
                                    <a href="javascript:void(0);">登录</a>
                                    <span>/</span>
                                    <a href="javascript:void(0);">注册</a>
                                </li>
                                <li class="header-signin" v-if="isLogin" @click="onChangeMenuOpen">
                                    <a href="javascript:void(0);">{{ userInfo.nickname }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="home-page--menu--list" :class="isMenuOpen ? 'is_show' : ''">
                            <div class="px-dropdown">
                                <div class="px-dropdown--container" v-if="isMenuOpen">
                                    <div class="px-dropdown--list">
                                        <ul class="px-dropdown-menu animated fadeIn">
                                            <div class="avatar-user">{{ userInfo.nickname }}</div>
                                            <div class="avatar-dropdown--divider"></div>
                                            <li class="px-dropdown-item flex u-f-ac">
                                                <div class="px-dropdown-item__icon">
                                                    <Icon size="16" color="rgba(162, 164, 168)" type="md-list-box" />
                                                </div>
                                                <div class="px-dropdown-item__title"><a href="/u/order" target="_blank">订单中心</a></div>
                                            </li>
                                            <li class="px-dropdown-item flex u-f-ac">
                                                <div class="px-dropdown-item__icon">
                                                    <i style="color: rgb(162, 164, 168);" class="el-icon-s-ticket"></i>
                                                </div>
                                                <div class="px-dropdown-item__title"><a href="/u/coupon" target="_blank">会员福利</a></div>
                                            </li>
                                            <li class="px-dropdown-item flex u-f-ac">
                                                <div class="px-dropdown-item__icon">
                                                    <Icon size="16" color="rgba(162, 164, 168)" type="md-person" />
                                                </div>
                                                <div class="px-dropdown-item__title"><a href="/user/setbindsns" target="_blank">个人设置</a></div>
                                            </li>
                                            <div class="avatar-dropdown--divider"></div>
                                            <li class="px-dropdown-item flex u-f-ac" @click="logout">
                                                <div class="px-dropdown-item__icon">
                                                    <Icon size="16" color="rgba(162, 164, 168)" type="md-log-out" />
                                                </div>
                                                <div class="px-dropdown-item__title">退出登录</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <WechatLogin/>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { navigationApi } from '@/api/common';
import WechatLogin from './wechat/login.vue';
import { getLogout } from '@/api/user';
export default {
    name: 'nav-header',
    components: { WechatLogin },
    data() {
        return {
            isKnowClass: false,
            selectUrl: '',
            menuList: [],
            logoList: {
                url: '',
                width: '',
            },
            isMenuOpen: false
        }
    },
    computed: {
        ...mapGetters(['isLogin', 'userInfo'])
    },
    created() {
        this.__loadData();
    },
    mounted() {
        this.selectUrl = this.$route.name;
        if(this.$route.name.includes("know") || this.$route.name.includes("gather") || this.$route.name.includes("product")){
            this.isKnowClass = true;
        }
    },
    methods: {
        __loadData () {
            this._navigationApi();
        },
        _navigationApi () {
            navigationApi('header').then(res => {
                if (res.status == 200) {
                    this.menuList = res.data.header;
                    this.logoList.url = res.data.site_logo;
                    this.logoList.width = res.data.site_logo_width;
                }
            });
        },
        login() {
            this.setWxchatLogin(true);
        },
        //展示菜单
        onChangeMenuOpen () {
            this.isMenuOpen = !this.isMenuOpen;
        },
        logout() {
            getLogout().then(res => {
                if (res.status == 200) {
                    this.onChangeMenuOpen();
                    this.$Message.success('退出成功');
                    this.$store.commit("LOGOUT");
                }
            });
        },
        ...mapMutations('wxchatLogin', ['setWxchatLogin'])
    }
}
</script>
<style lang="less" scoped>
.header{
    position: relative;
    height: 60px;
}
.w{height: 100%;}
.header-nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 998;
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,.06);
    &.know{
        border-bottom: 1px solid #F3F5F6;
        box-shadow: none;
    }
    .nav-header{
        position: relative;
        min-height: 0;
        height: 60px;
        // 登录样式
        .header-right{
            position: relative;
            float: right;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 60px;
            .login-area{
                float: left;
                position: relative;
                .header-unlogin{
                    font-size: 14px;
                    .header-signin{
                        a{
                            color: #545c63;
                        }
                        span{
                            padding: 0 4px;
                        }
                        &:hover{
                            a,span{
                                color: #1966ff;
                            }
                        }
                    }
                }
            }
            .home-page--menu--list{
                position: absolute;
                top: 46px;
                right: 0;
                z-index: 999;
                transition: .5s;
                opacity: 1;
                transition: all .3s ease-in-out;
                visibility: hidden;
                &.is_show{
                    visibility: visible;
                    opacity: 1;
                    // top: 36px;
                }
                .avatar-user {
                    width: 184px;
                    padding: 8px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .px-dropdown {
                    position: relative;
                    .px-dropdown--container{
                        width: 200px;
                        min-height: 200px;
                        padding: 4px;
                        border-radius: 8px;
                        background-color: #fff;
                        border: 1px solid #12112a08;
                        box-shadow: 0 2px 12px 0 #19191a0a,0 6px 20px 0 #19191a0f;
                        .px-dropdown-menu {
                            display: grid;
                            list-style-type: none;
                            .px-dropdown-item {
                                height: 36px;
                                padding-left: 8px;
                                padding-right: 8px;
                                position: relative;
                                background-color: #fff;
                                color: #19191a;
                                border-radius: 4px;
                                font-size: 14px;
                                cursor: pointer;
                                a{
                                    color: #19191a;
                                }
                                &:hover {
                                    background-color: #12112a0d;
                                }
                                .px-dropdown-item__title{
                                    margin-left: 8px;
                                }
                                .icon{
                                    color: rgb(162, 164, 168);
                                }
                            }
                            .avatar-dropdown--divider {
                                position: relative;
                                height: 5px;
                                padding-top: 4px;
                                padding-bottom: 4px;
                                margin: 0 8px;
                                &:before {
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    width: 100%;
                                    display: block;
                                    content: "";
                                    height: 1px;
                                    background: #13122b12;
                                }
                            }
                        }
                    }
                }
            }
        }
        // 导航样式
        .container,.header-logo,.el-image{
            height: 60px;
        }
        .image{
            width: 254px;
        }
        .container{
            display: flex;
            float: left;
            .logo-link{
                align-items: center;
                display: flex;
                height: 60px;
                margin-right: 20px;
            }
            .header-menu{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                height: 100%;
                margin: 0;
                padding: 0;
                position: relative;
                .header-menu-item{
                    cursor: pointer;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    /deep/.ivu-dropdown-rel{
                        line-height: 60px;
                        height: 60px;
                    }
                    .header-menu-itemLink{
                        align-items: center;
                        background: none;
                        border: 0;
                        border-radius: 0;
                        color: #1f2329;
                        cursor: pointer;
                        display: flex;
                        font-size: 16px;
                        height: 100%;
                        letter-spacing: 0;
                        line-height: 24px;
                        margin: 0 14px;
                        padding: 0;
                        position: relative;
                        text-align: center;
                        word-break: break-all;
                        // &:before {
                        //     background: #3370ff;
                        //     content: "";
                        //     display: block;
                        //     height: 2px;
                        //     opacity: 0;
                        //     position: absolute;
                        //     top: 58px;
                        //     width: 100%;
                        // }
                        &.product{
                            &:hover{
                                .head-nav-item-content{
                                    display: block;
                                }
                            }
                        }
                        &:hover,&.selected{
                            color: #1966ff;
                        }
                        .head-nav-item-content{
                            display: none;
                        }
                        i{
                            font-size: 12px;
                            font-weight: 500;
                            padding-left: 4px;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .header-menu-itemLink{
                            margin: 0 0 0 15px;
                        }
                    }
                    .head-nav-item-content{
                        position: fixed;
                        top: 60px;
                        left: 0px;
                        border-top: 1px solid #dee0e3;
                        width: 100vw;
                        height: auto;
                        overflow: hidden;
                        box-shadow: 0 27px 32px 0 rgba(23,26,29,.1);
                        transition: height 0.4s;
                        background: #fff;
                        z-index: 99;
                        .item-product-list{
                            .item-product-list-right{
                                display: flex;
                                font-size: 14px;
                                font-weight: 600;
                                margin: 12px 0 0;
                                .item-product-list-item{
                                    width: 251px;
                                    text-align: left;
                                    padding-right: 36px;
                                    .item-product-list-item-title{
                                        margin: 0px 16px;
                                        padding: 18px 0;
                                        font-size: 14px;
                                        color: #242932;
                                        font-weight: 400;
                                        border-bottom: 1px solid #DCDFE6;
                                    }
                                    .item-product-list-item-desc{
                                        padding: 18px 0 0px 0;
                                        a{
                                            color: #1f2329;
                                        }
                                        div{
                                            height: 38px;
                                            line-height: 38px;
                                            margin-top: 8px;
                                        }
                                        span{
                                            display: block;
                                            width: 200px;
                                            height: 36px;
                                            border-radius: 6px;
                                            line-height: 36px;
                                            padding-left: 16px;
                                            font-size: 14px;
                                            &:hover{
                                                background-color: #f2f6ff;
                                            }
                                        }
                                    }
                                }
                                @media screen and (max-width: 1024px) {
                                    .item-product-list-item{
                                        width: 196px;
                                        padding-right: 12px;
                                        .item-product-list-item-desc{
                                            width: 192px;
                                        }
                                    }
                                }
                            }
                        }
                        .whole{
                            text-align: left;
                            margin-bottom: 30px;
                            span{
                                display: block;
                                width: 140px;
                                height: 36px;
                                border-radius: 6px;
                                line-height: 36px;
                                padding-left: 16px;
                                font-size: 14px;
                                &:hover{
                                    background-color: #f2f6ff;
                                }
                                a{
                                    color: #1c1f21;
                                }
                            }
                        }
                        .el-divider--horizontal{
                            margin: 24px 18px;
                        }
                    }
                }
            }
        }
    }
}
</style>