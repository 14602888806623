import BasicLayout from '@/components/main';

const pre = 'customers'

export default {
    path: '/customers',
    name: 'customers',
    header: 'customers',
    redirect: {
        name: `${pre}customers`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/customers/customers.vue')
        },
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/customers/detail.vue')
        }
    ]
}
