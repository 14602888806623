import BasicLayout from '@/components/main';

const pre = 'trade'

export default {
    path: '/trade',
    name: 'trade',
    header: 'trade',
    redirect: {
        name: `${pre}trade`
    },
    component: BasicLayout,
    children: [
        {
            path: '/',
            name: `${pre}`,
            component: () => import('@/pages/trade/trade.vue')
        },
        {
            path: ':detail',
            name: `${pre}`,
            component: () => import('@/pages/trade/detail.vue')
        }
    ]
}
